.site-layout .site-layout-background {
  background: #fff;
}

.ant-picker .ant-picker-range {
  width: 100%;
}

.ant-table-wrapper {
  overflow: auto;
}

.ant-picker {
  width: 100%;
}
